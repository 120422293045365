import React, { Component } from 'react';

import { BrowserRouter , Routes, Route } from "react-router-dom";

import './scss/style.scss';

const AboutUs = React.lazy(() => import('./pages/aboutus/AboutUs'));
const Home = React.lazy(() => import('./pages/home/Home'));
const Privacy = React.lazy(() => import('./pages/privacy/Privacy'));
const Terms = React.lazy(() => import('./pages/terms/Terms'));
const HowUse = React.lazy(() => import('./pages/howuse/HowUse'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          {/* public section */}
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/howuse" element={<HowUse />} />

        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
